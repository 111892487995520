import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faMessage,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { generateBubbleChat } from "../apis";
import "../style.css";
import { useSelector } from "react-redux";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { useLocation } from "react-router-dom";

function BubbleChat({
  activeChat,
  setActiveChat,
  setQueries,
  questionList,
  setQuestionList,
  avatar,
  session_id,
}) {
  const serverAddress = process.env.REACT_APP_URL;
  const activeModel = useSelector((store) => store.auth.activeModel);
  const [question, setQuestion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesContainerRef = useRef(null);
  const timestampedQuestions = useRef([]);
  const location = useLocation();
  const [initialMessage, setInitialMessage] = useState("How can I help you?");
  const [darkMode, setDarkMode] = useState(false);
  const [isLMSStyle, setIsLMSStyle] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  // For typewriter effect
  const [displayedText, setDisplayedText] = useState("");
  const [typingIndex, setTypingIndex] = useState(0);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const startingMessage = params.get("starting_message");
    const styleParam = params.get("style");

    if (startingMessage) {
      setInitialMessage(startingMessage);
    }

    if (styleParam === "lms") {
      setIsLMSStyle(true);
    }
  }, [location]);

  // Typewriter effect logic
  useEffect(() => {
    const lastMessageIndex = activeChat.queries.length - 1;
    const lastMessage = activeChat.queries[lastMessageIndex]?.solution || "";
    
    if (lastMessage && typingIndex < lastMessage.length) {
      const timer = setTimeout(() => {
        setDisplayedText(lastMessage.slice(0, typingIndex + 1));
        setTypingIndex(typingIndex + 1);
      }, 50); // Adjust typing speed by modifying the delay here

      return () => clearTimeout(timer);
    }
  }, [activeChat.queries, typingIndex]);

  // Scroll to bottom when displayedText changes
  useEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  }, [displayedText]);

  const handleSendMessage = async () => {
    if (!question) {
      return;
    }

    if (isLoading) {
      toast.error(
        "Please wait for the current response before sending another message.",
        {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      return;
    }

    const timestamp = new Date().toLocaleString();
    const messageWithTimestamp = `${question} (Sent at: ${timestamp})`;

    setQuestionList([...questionList, question]);
    timestampedQuestions.current.push(messageWithTimestamp);

    let payload = {
      question: messageWithTimestamp,
      modelId: activeModel,
      session_id,
    };

    if (!activeChat.id) {
      const splitQues = question.split(" ");
      payload.isNew = true;
      payload.title =
        splitQues[0] + " " + (splitQues[1] || "") + " " + (splitQues[2] || "");
    } else {
      payload.id = activeChat.id;
    }
    payload.isBubble = true;
    setIsLoading(true);
    setQuestion("");
    await generateBubbleChat(payload)
      .then((res) => {
        setQueries(res.data.chats);

        const oldActiveChat = res.data.chats.find(
          (chat) => chat.id === activeChat.id
        );
        const chat = oldActiveChat
          ? oldActiveChat
          : res.data.chats[res.data.chats.length - 1];
        setActiveChat(chat);
        setIsLoading(false);
        setTypingIndex(0); // Reset typing index for new message
        setDisplayedText(""); // Clear displayed text for new message
      })
      .catch((err) => {
        console.log("error ", err);
        toast("Something went wrong. Please check retrain model status", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setIsLoading(false);
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleSendMessage();
    }
  };

  const handleInputChange = (e) => {
    setQuestion(e.target.value);
  };

  const handleBubbleClick = () => {
    setIsOpen(!isOpen);
  };

  if (isLMSStyle) {
    const lastMessageIndex = activeChat.queries.length - 1;

    return (
      <div className="flex items-center justify-center h-full w-full fixed top-0 left-0">
        <div className="flex items-center justify-center h-full w-full">
          <div
            className="relative"
            style={{
              width: '600px',
              height: '800px',
              backgroundColor: 'transparent',
              zIndex: 0,
            }}
          >
            <img
              src={avatar ? `${serverAddress}/avatar/${avatar}` : "/images/default_user.jpg"}
              alt="Background"
              style={{
                width: '600px',
                height: '800px',
                position: 'absolute',
                top: 0,
                left: 0,
                objectFit: 'cover',
                zIndex: 0,
              }}
            />

            <button
              onClick={() => setDarkMode(!darkMode)}
              className="absolute top-4 left-4 p-2 bg-gray-800 text-white rounded"
              style={{ zIndex: 1000 }}
            >
              {darkMode ? "Light Mode" : "Dark Mode"}
            </button>

            <div
              className={`flex flex-col justify-between h-full w-full relative z-10 ${
                darkMode
                  ? "bg-gray-900 bg-opacity-80 text-white"
                  : "bg-white bg-opacity-80 text-black"
              }`}
            >
              <div
                className="h-full overflow-y-auto p-4 relative"
                ref={messagesContainerRef}
              >
                {displayedText ? (
                  <div
                    className="flex flex-col items-end my-2 absolute chat-bubble"
                    style={{
                      top: "33%",
                      right: 0,
                      width: "50%",
                      marginLeft: "auto",
                    }}
                  >
                    <div
                      className="p-4 rounded-lg bg-indigo-700 text-white text-left text-xl relative"
                      style={{
                        width: "100%",
                        borderRadius: "15px",
                        backgroundColor: "#4A90E2",
                      }}
                    >
                      {/* Typewriter effect text */}
                      <span>{displayedText}</span>
                      <div
                        className="chat-bubble-tail"
                        style={{
                          position: "absolute",
                          top: "10px",
                          left: "-15px",
                          width: "0",
                          height: "0",
                          borderTop: "15px solid transparent",
                          borderBottom: "15px solid transparent",
                          borderRight: "15px solid #4A90E2",
                        }}
                      ></div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="flex flex-col items-end my-2 absolute chat-bubble"
                    style={{
                      top: "33%",
                      right: 0,
                      width: "50%",
                      marginLeft: "auto",
                    }}
                  >
                    <div
                      className="p-4 rounded-lg bg-indigo-700 text-white text-left text-xl relative"
                      style={{
                        width: "100%",
                        borderRadius: "15px",
                        backgroundColor: "#4A90E2",
                      }}
                    >
                      <span>{initialMessage}</span>{" "}
                      {/* Show the initial message here */}
                      <div
                        className="chat-bubble-tail"
                        style={{
                          position: "absolute",
                          top: "10px",
                          left: "-15px",
                          width: "0",
                          height: "0",
                          borderTop: "15px solid transparent",
                          borderBottom: "15px solid transparent",
                          borderRight: "15px solid #4A90E2",
                        }}
                      ></div>
                    </div>
                  </div>
                )}
              </div>
              <div className="p-4">
                <div className="w-full h-[50px] border border-gray-600 flex items-center rounded-lg p-2">
                  <input
                    value={question}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    className="h-full w-full p-2 outline-none bg-inherit text-left"
                    type="text"
                    placeholder="Type a message..."
                  />
                  <button
                    onClick={handleSendMessage}
                    className="h-full p-2 rounded-lg bg-blue-500 hover:bg-blue-700 text-white flex items-center justify-center"
                  >
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    // Default style rendering
    return (
      <div>
        <div
          className={`flex flex-col justify-between bg-transparent rounded-t-lg mb-4 h-full w-full pt-16 pl-4 fixed right-4 bottom-12 shadow-lg border-1 rounded-lg overflow-hidden transition-transform duration-300  ${
            isOpen ? "" : "transform translate-y-full opacity-0"
          } `}
        >
          <div className="bg-sky-900 w-full h-12 flex justify-start items-center rounded-t-lg">
            <img
              src={
                avatar
                  ? `${serverAddress}/avatar/${avatar}`
                  : "/images/default_bot.png"
              }
              className="w-10 ml-2 rounded-lg"
            />
          </div>
          <div className="h-full overflow-y-scroll bg-white">
            <div
              className={`${
                questionList.length > 0 || activeChat.queries.length > 0
              } rounded overflow-y-scroll  w-full  mx-auto md:p-0 p-4 flex flex-col`}
            >
              <div className={`text-sm font-bold flex justify-start my-1`}>
                <img
                  src={
                    avatar
                      ? `${serverAddress}/avatar/${avatar}`
                      : "/images/default_bot.png"
                  }
                  className="w-10 h-10 rounded-lg ml-1"
                />
                <span className="font-bold ml-1 bg-gray-300 p-2 rounded-lg">
                  {initialMessage}
                </span>
              </div>
              {questionList.length > 0 &&
                questionList.map((message, index) => (
                  <div key={index}>
                    <div className="flex flex-col items-end w-full my-1 ">
                      <p className="p-2 rounded-lg bg-indigo-700 text-white">
                        {message}
                      </p>
                    </div>

                    <div className={`text-sm font-bold flex justify-start my-1`}>
                      <img
                        src={
                          avatar
                            ? `${serverAddress}/avatar/${avatar}`
                            : "/images/default_bot.png"
                        }
                        className="w-10 h-10 rounded-lg ml-1"
                      />
                      <span className="font-bold ml-1 bg-gray-300 p-2 rounded-lg">
                        {isLoading && questionList.length - 1 === index ? (
                          <span className="flex justify-center items-center h-full">
                            <img src="/images/dots.gif" className="w-12" />
                          </span>
                        ) : (
                          activeChat.queries
                            .filter(
                              (ans) =>
                                ans.question ===
                                timestampedQuestions.current[index]
                            )
                            .map((ans, ansIndex) => (
                              <p key={ansIndex}>
                                <Markdown remarkPlugins={[remarkGfm]}>
                                  {ans.solution}
                                </Markdown>
                              </p>
                            ))
                        )}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
          <div className="p-4 bg-white">
            <div className="w-full h-[50px] border border-gray-600 flex items-center rounded-lg p-2">
              <input
                value={question}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className={`h-full w-full p-2 outline-none bg-inherit`}
                type="text"
                placeholder="Type a message..."
              />
              <button
                onClick={handleSendMessage}
                className="h-full p-2 rounded-lg icon-style text-[#ececf1]"
              >
                <FontAwesomeIcon icon={faPaperPlane} />
              </button>
            </div>
            <p className="text-xs text-white p-2 text-center"></p>
            <ToastContainer />
          </div>
        </div>
        <button
          onClick={handleBubbleClick}
          className="bg-sky-900 rounded-full w-[50px] h-[50px] p-2 shadow-lg text-white fixed bottom-1 right-4"
        >
          <FontAwesomeIcon icon={isOpen ? faChevronDown : faMessage} />
        </button>
      </div>
    );
  }
}

export default BubbleChat;

