import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const generateChat = (query) => {
  return axios.post(`${API_URL}/user_query/chat`, query, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const generateBubbleChat = (query) => {
  return axios.post(`${API_URL}/user_query_bubble/chat`, query, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

// delete chat
export const deleteChat = (id) => {
  return axios.post(`${API_URL}/user_query/deleteChat`, { id });
};

export const getAllQueries = (session_id) => {
  console.log("API Call with session_id:", session_id); // Debug log
  return axios.get(`${API_URL}/user_query`, {
    params: {
      session_id: session_id,
    },
  });
};

export const verifyURL = async (tokens) => {
  try {
    const { data } = await axios.post(
      `${API_URL}/other/verifyHashURL`,
      tokens,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    sessionStorage.setItem("user", JSON.stringify(data));
    return data;
  } catch (error) {
    console.log("error: ", error);
  }
};
